import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PopupContainer from './PopupContainer';
import Input from '../../utils/common/Input';
import Textarea from '../../utils/common/Textarea';
import Button from '../../utils/common/Button';
import media from '../../utils/helpers/media';

const ApplyResumePopupWrapper = styled.div`
  width: 100%;
`;

const ApplyResumePopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 540px;
  align-items: center;
  ${media.phone`
    max-width: 320px;
    padding: 0 20px;
  `}
`;

const ApplyResumeInputContainer = styled.div`
  width: 100%;
  margin-bottom: 18px;
`;

const ApplyResumeTitle = styled.h2`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 25px;
  font-weight: 400;
  text-align: center;
`;

const ApplyResumeDescription = styled.p`
  font-family: Avenir Next;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  margin-bottom: 25px;
`;

const ApplyResumeButton = styled(Button)`
  width: 296px;
  min-height: 56px;
  margin: 20px 0;
  ${media.phone`
    max-width: 320px;
    width: 100%;
  `}
`;

const ApplyResumePopup = ({ closeModal, sendResume, title }) => {
  return (
    <ApplyResumePopupWrapper>
      <PopupContainer closeModal={closeModal}>
        <ApplyResumePopupContainer>
          <ApplyResumeTitle>Отклик на вакансию {`"${title}"`}</ApplyResumeTitle>
          <ApplyResumeDescription>
            Коротко расскажите о себе и о том, почему вам интересна эта работа
          </ApplyResumeDescription>
          <ApplyResumeInputContainer>
            <Textarea label="Сопроводительный текст:" labelFor="text" />
          </ApplyResumeInputContainer>
          <ApplyResumeInputContainer>
            <Input label="Ссылка на резюме:" labelFor="link" />
          </ApplyResumeInputContainer>
          <ApplyResumeInputContainer>
            <Input label="E-mail:" labelFor="email" />
          </ApplyResumeInputContainer>
          <ApplyResumeButton text="Отправить заявку" onClick={sendResume} />
        </ApplyResumePopupContainer>
      </PopupContainer>
    </ApplyResumePopupWrapper>
  );
};

ApplyResumePopup.propTypes = {
  closeModal: PropTypes.func.isRequired,
  sendResume: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
};

export default ApplyResumePopup;
