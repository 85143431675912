/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import styled from 'styled-components';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Overlay from '../utils/common/Overlay';
import ApplyResumePopup from '../components/Vacancy/ApplyResumePopup';
import ThanksResumePopup from '../components/Vacancy/ThanksResumePopup';
import media from '../utils/helpers/media';
import vars from '../utils/styles/vars';
import Button from '../utils/common/Button';
import arrowRight from '../assets/icons/arrow_left.svg';

const VacancyContainer = styled.div`
  max-width: 1440px;
  margin: 0 auto;
`;

const VacancyContainerHeader = styled.div`
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  margin: 0 100px;
  ${media.phone`
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 0;
  ;`}
  ${media.desktop`
    margin: 0 10px;
  ;`}
`;

const VacancyBackButton = styled.button`
  color: ${vars.colors.primaryColor};
  background: #fff;
  border: none;
  display: flex;
  align-items: center;
  font-family: Avenir Next;
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0.75px;
  text-transform: uppercase;
  position: absolute;
  left: 0;
  cursor: pointer;
  top: 34px;

  &:focus {
    outline: none;
  }

  &:hover {
    text-decoration: underline;
  }
  &:before {
    content: '';
    width: 40px;
    height: 40px;
    background: url(${arrowRight}) no-repeat center center;
  }
  ${media.phone`
    position: static;
  ;`}
  ${media.desktop`
    top: 25px;
  ;`}
`;

const VacancyTitle = styled.h1`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 38px;
  text-align: center;
  letter-spacing: 0.25px;
  font-weight: 500;
  margin-bottom: 10px;
  ${media.phone`
    padding: 10px 15px 0;
    font-size: 22px;
    margin: 0;
    align-self: center;
  ;`}
`;

const VacancyDescription = styled.div`
  background: #fff;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  padding: 50px;
  max-width: 844px;
  margin: 0 auto 200px;
  ${media.phone`
    padding: 25px;
    box-shadow: none;
    margin-bottom: 30px;
  ;`}
`;

const VacancyText = styled.div`
  font-family: Avenir Next;
  font-size: 16px;
  line-height: 26px;
  & h2 {
    margin: 0;
    font-size: 16px;
  }
  & ul {
    margin-bottom: 25px;
    list-style-position: inside;
  }
  & > p {
    margin-bottom: 25px;
  }
`;

const VacancyButtonRequest = styled(Button)`
  width: 289px;
  min-height: 56px;
  margin: 65px auto 5px;
  ${media.phone`
    width: 100%;
  ;`}
`;

const vacancy = {
  title: 'Директор по маркетингу',
  description: `
    <p>Мы ищем человека, способного создать и возглавить сильный отдел, который будет разрабатывать как повседневные, так и инновационные решения для продвижения услуг и продуктов компании в России.</p>
    <h2>Мы ищем того, кто</h2>
    <ul>
      <li>Понимает, что значит создавать маркетинговые решения, которые одновременно интересуют потребителя и отвечают бизнес-задачам компании</li>
      <li>Умеет находить профессионалов в области маркетинга и руководить их работой</li>
      <li>Имеет опыт работы в маркетинге и рекламе, желательно – в области решений B2C</li>
      <li>Умеет работать с аналитикой и понимает, как переводить цифры в действия</li>
      <li>Умеет работать с маркетинговыми исследованиями и переводить их результаты в действия</li>
      <li>Умеет выстраивать единое, связное пространство коммуникаций, усиливающих и поддерживающих друг друга</li>
      <li>Имеет опыт продвижения нестандартных и/или инновационных продуктов и решений</li>
    </ul>
    <h2>Скорее всего, у этого человека</h2>
    <ul>
      <li>Высшее образование</li>
      <li>Опыт работы в руководящей маркетинговой позиции</li>
      <li>Умение тактично, внимательно и последовательно работать с командой в качестве направляющего</li>
    </ul>
    <h2>Мы предлагаем</h2>
    <ul>
      <li>Достойный оклад</li>
      <li>Работу в отличном офисе в центре города</li>
      <li>Современные методы работы без бюрократии и лишней траты времени</li>
      <li>Возможность участвовать в создании и продвижении продукта, о котором будут много говорить</li>
    </ul>
    <h2>Как предложить свою кандидатуру</h2>
    <ul>
      <li>Пожалуйста, отправьте нам письмо по адресу head.of.marketing@mango.rocks</li>
      <li>В заголовке письма укажите "Директор по маркетингу"</li>
      <li>Коротко расскажите о себе и о том, почему вам интересна эта работа</li>
      <li>Приложите резюме</li>
    </ul>
    <h2>Спасибо и до скорого знакомства!</h2>
  `
};

const VacancyPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);

  const openModal = () => {
    document.body.classList.add('modal-open');
    setIsModalOpen(true);
  };

  const closeModal = () => {
    document.body.classList.remove('modal-open');
    setIsModalOpen(false);
    setIsResultModalOpen(false);
  };

  const sendResume = () => {
    setIsResultModalOpen(true);
  };

  return (
    <Layout>
      <SEO title="Посадочная" />
      <VacancyContainer>
        <VacancyContainerHeader>
          <VacancyBackButton
            // to="/about"
            onClick={() => {
              // eslint-disable-next-line no-unused-expressions
              // eslint-disable-next-line no-restricted-globals
              // eslint-disable-next-line no-undef
              typeof history !== 'undefined' && history.go(-1);
            }}
          >
            Назад
          </VacancyBackButton>
          <VacancyTitle>{vacancy.title}</VacancyTitle>
        </VacancyContainerHeader>
        <VacancyDescription>
          <VacancyText
            dangerouslySetInnerHTML={{ __html: vacancy.description }}
          />
          <VacancyButtonRequest text="Откликнуться" onClick={openModal} />
        </VacancyDescription>
      </VacancyContainer>
      {isModalOpen && (
        <Overlay>
          {isResultModalOpen ? (
            <ThanksResumePopup closeModal={closeModal} />
          ) : (
            <ApplyResumePopup
              closeModal={closeModal}
              sendResume={sendResume}
              title={vacancy.title}
            />
          )}
        </Overlay>
      )}
    </Layout>
  );
};

export default VacancyPage;
