import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import vars from '../styles/vars';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
`;
const Label = styled.label`
  color: #818a97;
  margin-left: 5px;
  margin-bottom: 6px;
  display: inline-block;
`;

const StyledInput = styled.input`
  border: 1px solid #d9dce6;
  background: white;
  width: 100%;
  height: 56px;
  border-radius: 8px;
  transition: border 0.3s ease;
  padding: 4px 16px;
  font-family: Avenir Next;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.25px;
  &:focus {
    outline: none;
    border: 1px solid ${vars.colors.primaryColor};
  }

  &:focus + ${Label} {
    color: ${vars.colors.primaryColor};
  }
`;

const Input = ({ label, labelFor }) => {
  const [inputValue, setInputValue] = useState('');

  return (
    <InputContainer>
      <StyledInput
        type="text"
        id={labelFor}
        onChange={e => setInputValue(e.target.value)}
        value={inputValue}
      />
      <Label htmlFor={labelFor}>{label}</Label>
    </InputContainer>
  );
};

Input.propTypes = {
  label: PropTypes.string.isRequired,
  labelFor: PropTypes.string.isRequired
};

export default Input;
