import React from 'react';
import styled from 'styled-components';
import media from '../helpers/media';

const OverlayContainer = styled.div`
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  ${media.phone`
    background: #fff;
    height: 100%;
    max-height: 100%;
    overflow: auto;
  ;`}
`;

const Overlay = ({ children }) => {
  return <OverlayContainer>{children}</OverlayContainer>;
};

export default Overlay;
