import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import media from '../../utils/helpers/media';
import vars from '../../utils/styles/vars';
import useMedia from '../../hooks/useMedia';
import arrowBack from '../../assets/icons/back.svg';
import logo from '../../assets/images/header-home.svg';

const PopupContainerWrapper = styled.div`
  padding: 33px 30px 30px;
  width: 952px;
  min-height: 462px;
  margin: 110px auto 0;
  box-sizing: border-box;
  box-shadow: 0px 4px 8px rgba(176, 190, 197, 0.24);
  border-radius: 8px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #fff;
  ${media.phone`
    width: 100%;
    border: none;
    box-shadow: none;
    border-radius: 0;
    padding: 0 34px 34px;
    margin-top: 0;
    min-height: none;
    height: auto;
    justify-content: flex-start;
  `};
`;

const PopupBackButton = styled.div`
  width: 40px;
  height: 40px;
  position: absolute;
  top: 25px;
  left: -34px;
  background: url(${arrowBack});
`;

const PopupMobileHeader = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  padding: 25px 0;
  width: 100%;
  margin-bottom: 35px;
`;

const PopupCloseButton = styled.button`
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 11px;
  right: 11px;
  border: none;
  background: transparent;
  cursor: pointer;
  outline: none;
  &:before {
    content: '';
    position: absolute;
    top: 20px;
    left: 10px;
    width: 20px;
    height: 2px;
    background-color: ${vars.colors.primaryColor};
    transform: rotate(45deg);
  }
  &:after {
    content: '';
    position: absolute;
    top: 20px;
    left: 10px;
    width: 20px;
    height: 2px;
    background-color: ${vars.colors.primaryColor};
    transform: rotate(-45deg);
  }
`;

const PopupContainer = ({ children, closeModal }) => {
  const small = useMedia('(max-width: 992px)');

  return (
    <PopupContainerWrapper>
      {small ? (
        <PopupMobileHeader>
          <PopupBackButton onClick={closeModal} />
          <img
            src={logo}
            width="108"
            height="28"
            alt="Логотип Манго"
            aria-label="Назад"
          />
        </PopupMobileHeader>
      ) : (
        <PopupCloseButton onClick={closeModal} />
      )}
      {children}
    </PopupContainerWrapper>
  );
};

PopupContainer.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default PopupContainer;
