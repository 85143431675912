import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PopupContainer from './PopupContainer';
import Button from '../../utils/common/Button';

const ThanksResumePopupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ThanksResumeTitle = styled.h2`
  margin: 90px 0 20px;
  font-family: Gilroy;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
`;

const ThanksResumeText = styled.p`
  font-family: Gilroy;
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
  text-align: center;
  margin-bottom: 67px;
`;

const ButtonOk = styled(Button)`
  width: 235px;
  min-height: 56px;
`;

const ThanksResumePopup = ({ closeModal }) => {
  return (
    <PopupContainer closeModal={closeModal}>
      <ThanksResumePopupContainer>
        <ThanksResumeTitle>Спасибо за отклик!</ThanksResumeTitle>
        <ThanksResumeText>
          Мы рассмотрим вашу кандидатуру и свяжемся с вами.
        </ThanksResumeText>
        <ButtonOk text="Хорошо" onClick={closeModal} />
      </ThanksResumePopupContainer>
    </PopupContainer>
  );
};

ThanksResumePopup.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default ThanksResumePopup;
